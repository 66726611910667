<template>
  <div class="run-mode-page">
    <div class="card-title">累计运动方式统计</div>
    <div class="canvas-desc flex align-center justify-end">
      <div class="flex align-center">
        <i style="background: #f53f8c"></i>
        <span>跑步人次</span>
      </div>
      <div class="flex align-center">
        <i style="background: #52ffff"></i>
        <span>步行人次</span>
      </div>
    </div>
    <div class="run-mode-canvas" id="run-mode-canvas">
      <div id="mode-canvas"  ref="runCanvas"></div>
    </div>

  </div>
</template>

<script setup>
import { getDimRUnMode } from '../../../api/count'
import { getDay } from '../../../utils/util'
import { onMounted, ref } from 'vue'
// 引入echarts
import * as echarts from "echarts";


onMounted(() => {
    getDimRUnMode({startTime: getDay(-6), endTime: getDay()}).then(res => {
        let runDateArr = res.map(item => item.dimRun);
        let walkDateArr = res.map(item => item.dimWalk);
        let xData = res.map(item => item.startTime.split('-')[2])

        initEchart(xData,walkDateArr,runDateArr)

    })
})


const runCanvas = ref(null)
// 初始化Echarts
const initEchart = (xData,optionWalkArr,optionRunArr) => {
  let option = {
    xAxis: {
      data: xData,
      type: "category",
      axisTick: {
        show: false,
      },
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: "#fff",
        fontSize: 12,
        lineHeight: 20,
      },
      axisLine: {
        lineStyle: {
          color: "rgba(12,102,173,.5)",
          // width: 20,
        },
      },
    },
    yAxis: {
      type: "value",
      axisTick: {
        show: false, //不显示刻度线
      },
      axisLabel: {
        color: "#fff", //y轴上的字体颜色
        fontSize: 12,
        lineHeight: 20,
      },
      axisLine: {
        lineStyle: {
          width: 2,
          color: "rgba(12,102,173,.5)", //y轴的轴线的宽度和颜色
        },
      },
      splitLine: {
        show: false,
      },
    },
    grid: {
      left: "4%",
      right: "4%",
      top: "5%",
      bottom: "1%",
      containLabel: true,
    },
    series: [
      //  跑步人次
      {
        data: optionRunArr,
        type: "line",
        symbol: "none",
        smooth: true,
        itemStyle: {
          normal: {
            color: "#E24F95",
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, .1, [
              {
                offset: 0,
                color: "#E24F95",
              },
              {
                offset: 1,
                color: "rgba(226,79,149,.01)",
              },
            ]),
          },
        },
      },
      // 步行人次
      {
        data: optionWalkArr,
        type: "line",
        symbol: "none",
        smooth: true,
        itemStyle: {
          normal: {
            color: "#3A92A1",
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, .1, [
              {
                offset: 0,
                color: "#3A92A1",
              },
              {
                offset: 1,
                color: "rgba(58,146,161,.1)",
              },
            ]),
          },
        },
      },
    ],
  };
  let runModeCanvas = echarts.init(runCanvas.value);
  runModeCanvas.setOption(option);
};


</script>

<style lang="scss" scoped>
.run-mode-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.canvas-desc {
    width: 100%;
    margin: 0.25rem 0 0.375rem;
    & > div {
      i {
        width: 0.25rem;
        height: 0.1rem;
        background: #f53f8c;
        border-radius: 0.05rem;
        margin-right: 0.125rem;
      }
      font-size: 0.225rem;
      color: white;
      line-height: 1;
      margin-right: 0.375rem;
    }
  }
  .run-mode-canvas {
    width: 100%;
    flex: 1;
    overflow: hidden;
    #mode-canvas {
      width: 100%;
      height: 100%;
    }
  }

</style>
