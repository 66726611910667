<template>
  <div class="news-page-box">
    <div class="card-title">新闻资讯</div>
    <div class="news-list">
      <van-swipe class="my-swipe" :autoplay="6000">
        <van-swipe-item v-for="(item,index) in newsList" :key="index">
          <div class="new-label-item" v-for="(newItem,newIndex) in item" :key="newIndex" @click.stop="openNewDetail(newItem)">
            <div class="l-new-img"><img :src="$formattingImg(newItem.imageUrl)" alt=""></div>
            <div class="r-news-content">
              <div class="news-title">{{ newItem.title }}</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>

  <van-overlay :show="newShow" @click="newShow = false">
  <div class="wrapper">
    <div class="new-detail-black" @click.stop>
        <div class="news-title">{{ newDetail.title }}</div>
        <div class="news-content" v-html="newDetail.content"></div>
    </div>
  </div>
</van-overlay>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { fetchNewList } from '../../../api/count'

const newsList = ref([])
onMounted(() => {
    fetchNewList({ curren: 1, size: 999 }).then(res => {
      let resultNews = []
      for(let i = 0; i < res.records.length; i+=3) {
        resultNews.push(res.records.slice(i, i + 3))
      }
        newsList.value = resultNews
    })
})

const newShow = ref(false)
const newDetail = ref({})
const openNewDetail = detail => {
  newDetail.value = detail
  newShow.value = !newShow.value
}
</script>

<style lang="scss" scoped>
.news-page-box {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/news-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: .375rem .375rem 0;
  box-sizing: border-box;
  overflow: hidden;
  .news-list {
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
.new-label-item {
  display: flex;
  cursor: pointer;
  border-bottom: solid 1px #666666;
  padding: .2rem 0;
  .l-new-img {
    width: 1.125rem;
    height: .75rem;
    margin-right: .212rem;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .r-news-content {
    .news-title {
      font-size: .2rem;
      color: #BBE3F3;
      line-height: 2;
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:last-child {
    border-bottom: none !important;
  }
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .new-detail-black {
      width: 60vw;
      height: 90vh;
      background-color: white;
      overflow: scroll;
      padding: .5rem;
      box-sizing: border-box;
      .news-title {
        font-size: .375rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: .375rem;
        img {
          width: 100% !important;
        }
      }
    }
  }
</style>