<template>
<div class="home-box">
    <!-- 头部header -->
   <HRZBHEADER/>
    <div class="home-main flex justify-between" v-show="!faceType">
        <!-- 左侧栏目 -->
        <div class="slider-left">
          <div class="l-t-runBox mobile-none">
            <div><RUNLINEDATA/></div>
            <div class="run-mode-box"><RUNMODE/></div>
          </div>
          <div class="l-b-user">
            <div class="user-age-sex"><USERSEXAGE time="today"/></div>
            <div class="user-age-sex"><USERSEXAGE/></div>
          </div>
        </div>
        <!-- 中间栏目 -->
        <div class="main-center">
           <!-- 设备列表 -->
           <div class="device-use-box"><DEVICE/></div>
           <div class="device-use-ranking-box"><DEVICERANKING/></div>
        </div>
        <!-- 右侧排行榜 -->
        <div class="slider-right">
            <div class="ranking-box"> <RANKING/></div>
            <div class="news-box"> <NEWS/></div>
        </div>
    </div>

</div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router'
import { Toast } from "vant"
import { configStore } from "../../store/config";

import HRZBHEADER from '../../components/header'
import RUNLINEDATA from './components/runLineData.vue'
import USERSEXAGE from './components/userSexAge.vue'
import DEVICE from './components/device.vue'
import RANKING from './components/ranking.vue'
import NEWS from './components/news.vue'
import DEVICERANKING from './components/deviceRanking.vue'
import RUNMODE from './components/runMode.vue'
import { weaTherUseStore } from '../../store/weather';
// const configData = configStore()


const config = configStore();

// 初始化项目
const init = () => {
  weaTherUseStore().initWeaTher() // 天气
}

init()
</script>

<style lang="scss">
.home-box {
    padding: 0 .313rem .825rem;
    box-sizing: border-box;
    .home-main {
        width: 100%;
        padding-top: .0rem;
        // 左侧栏目
        .slider-left {
            width: 11.125rem;
           .l-t-runBox {
                width: 100%;
                height: 5.15rem;
                margin-bottom: .375rem;
                display: flex;
                padding: .313rem;
                box-sizing: border-box;
                background-image: url('../../assets/images/run-group-bg.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                .run-mode-box {
                    width: 100%;
                }
           }
           .l-b-user {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .user-age-sex {
                    width: 48%;
                    height: 6.34rem;
                }
           }
        }
        // 中间栏目
        .main-center {
            width: 5.325rem;
            margin: 0 .375rem;
           .device-use-box {
                width: 100%;
                height: 7.825rem;
                margin-bottom: .375rem;
           }
           .device-use-ranking-box {
                width: 100%;
                height: 3.663rem;
           }
        }
        // 右侧栏目 排行榜
        .slider-right {
           width: 5.688rem;
           .ranking-box {
            width: 100%;
            height: 6.875rem;
            margin-bottom: .375rem;
           }
            .news-box {
                width: 100%;
                height: 4.6rem;
            }
        }
    }
}
// 排行版
.rank-box {
    width: 6.425rem;
    height: 100%;
    border: solid 1px #216A7D;
    box-sizing: border-box;
}
.mark-fixed-box {
    font-size: .25rem;
    color: #5C7099;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: .275rem;
    letter-spacing: .1rem;
}

@media (max-width: 750px) {
    .mobile-none {
        display: none !important;
    }
    .slider-left {
        .l-b-user {
            .user-age-sex {
                width: 100% !important;
            }
        }
    }
    .home-main {
        display: flex;
        flex-direction: column;
        .slider-left {
            width: 100% !important;
            .l-b-user {
                display: flex;
                flex-direction: column;
                .user-age-sex {
                    margin: 0 0 .375rem !important;
                }
            }
        }
        .main-center {
            margin: 0 0 .375rem !important;
            width: 100% !important;
        }
        .slider-right {
            width: 100% !important;
        }
    }
}
</style>
