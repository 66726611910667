<template>
  <div class="run-line-box">
    <!-- 累计运动数据统计 -->
    <div class="run-l-box">
        <div class="card-title">累计运动数据统计</div>
        <div class="card-group-box">
            <div class="card-box">
                <CountTo :start-val="0" :end-val="data.countData.totalMotionDistance / 1000" :decimals="2" :duration="3000" class="run-num" />
                <div class="run-name">运动里程(km)</div>
            </div>
            <div class="card-box">
                <CountTo :start-val="0" :end-val="data.countData.totalMotionTime / 60 / 60" :decimals="2" :duration="3000" class="run-num" />
                <div class="run-name">运动时间(h)</div>
            </div>
            <div class="card-box">
                <CountTo :start-val="0" :end-val="data.countData.personNumber" :duration="3000" class="run-num" />
                <div class="run-name">运动人次</div>
            </div>
            <div class="card-box">
                <CountTo :start-val="0" :end-val="data.countData.totalMotionCalorie" :duration="3000" class="run-num" />
                <div class="run-name">消耗热量(kcal)</div>
            </div>
            <div class="card-box">
                <CountTo :start-val="0" :end-val="data.countData.person" :duration="3000" class="run-num" />
                <div class="run-name">运动人数</div>
            </div>
            <div class="card-box">
                <CountTo :start-val="0" :end-val="data.countData.personAverageMotion" :duration="3000" class="run-num" />
                <div class="run-name">平均运动人次</div>
            </div>
        </div>
    </div>

    <!-- 累计运动方式统计 -->
    <div class="run-r-box"></div>
  </div>
</template>

<script setup>
import CountTo from "../../../components/vue-countTo";
import { getMotionCount } from "../../../api/index"
import { getNumberCount } from "../../../api/count"
import { configStore } from "../../../store/config";
import { timeToDate, getDay } from "../../../utils/util"
import { onMounted, reactive, ref } from "vue";

let data = reactive({
    countData: {}
})

const initMotionCount = () => {
    // 累计运动数据
    getNumberCount({ startTime: getDay(-1999), endTime: getDay(9999) }).then(res => {
        if (res) data.countData = res
    })
}

onMounted(() => {
    initMotionCount()
})

</script>



<style lang="scss" scoped>
.run-line-box {
    width: 100%;
    height: 100%;
    display: flex;
    .run-l-box {
        flex: 1;
        .card-title {
            margin-bottom: .375rem;
        }
        .card-group-box {
            display: grid;
            grid-template-columns: repeat(2, 2.313rem);
            .card-box {
                height: 1rem;
                border: solid 1px #19D9FF;
                border-radius: .05rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: .4rem;
                .run-num {
                    font-size: .237rem;
                    color: #03DFFF;
                    line-height: 1;
                    font-weight: bold;
                }
                .run-name {
                    font-size: .212rem;
                    color: white;
                    margin-top: .188rem;
                    line-height: 1;
                }
                &:nth-child(1n+1) {
                    margin-right: .2rem;
                }
            }
        }
    }
    .run-r-box {
        // flex: 1;
    }
}
</style>