<template>
  <div class="ranking-page-box">
    <div class="t-box">
      <div class="card-title">排行榜</div>
      <div class="rank-name-select">
        <div v-for="(item,index) in rankingData.selectRankingArr" :key="index" :class="['label-name', rankingData.selectRankingIndex == index ? 'label-name-active' : '']" @click="labelNameRanking(index)">{{ item.name }}</div>
      </div>
    </div>
    <div class="ranking-b-box">
      <van-swipe
        class="my-swipe"
        :loop="false"
        :show-indicators="false"
        ref="remarkCarusel"
      >
        <van-swipe-item
          v-for="(item, index) in rankingData.selectRankingArr"
          :key="index"
        >
        <!--  :loop="index === Number(rankingData.selectRankingIndex)"
            :autoplay="index === Number(rankingData.selectRankingIndex) ? 4000 : 0" -->
          <van-swipe
            class="in-swiper-box"
           
            :show-indicators="false"
            indicator-color="white"
            @change="rankingInChange"
          >
            <van-swipe-item
              class="user-ranking-content"
              v-for="(labelItem, labelIndex) in item.rankingArr"
              :key="labelIndex"
            >
              <div
                class="user-ranking-box"
                v-for="(userItem, userIndex) in labelItem.rankDataArr"
                :key="userIndex"
              >
                <div class="ranking-item-label">
                  <div class="l-user-detail">
                     <!-- 排名编号 -->
                  <div class="rank-num">
                    <!-- <img src="../../assets/images/1.png" alt="1"> -->
                    <img v-if="userIndex < 3" :src="userIndex == 0 ? one : userIndex == 1 ? two : three" :alt="userIndex+1">
                    <span v-else>{{ userIndex+1 < 10 ? '0' + (userIndex + 1) : userIndex + 1 }}</span>
                  </div>

                    <div class="head-img">
                      <img
                        :src="$formattingImg(userItem.image)"
                        :alt="userItem.name"
                        @error="setImageSrc"
                      />
                    </div>
                    <div class="user-name">{{ userItem.name }}</div>
                  </div>
                  <div class="r-run-detail">
                    <div class="run-time">{{ labelItem.name }}</div>
                    <div class="run-num">
                    {{
                      item.id === 1
                        ? (userItem.distance / 1000).toFixed(2) + "KM"
                        : item.id === 2
                        ? userItem.calorie + "kcal"
                        : (userItem.time / 60).toFixed(2) + "min"
                    }}
                  </div>
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch, onMounted } from "vue";
import { rankingMotion, rankingCalorie, rankingTime } from "../../../api/count";
import { userInfoStore } from "../../../store/userInfo"
import { getDay } from "../../../utils/util";
import getDateFn from "../../../utils/day"
import one from "../../../assets/images/guanjumn.png";
import two from "../../../assets/images/yajun.png";
import three from "../../../assets/images/jijun.png";
import deficonHead from "../../../assets/images/deficon.png"

const rankingData = reactive({
  selectRankingArr: [
    {
      id: 1,
      name: "运动里程",
      rankingArr: [
        {
          id: 11,
          name: "今日",
          rankDataArr: [],
        },
        {
          id: 12,
          name: "本周",
          rankDataArr: [],
        },
        {
          id: 13,
          name: "本月",
          rankDataArr: [],
        },
      ],
    },
    {
      id: 2,
      name: "消耗热量",
      rankingArr: [
        {
          id: 11,
          name: "今日",
          rankDataArr: [],
        },
        {
          id: 12,
          name: "本周",
          rankDataArr: [],
        },
        {
          id: 13,
          name: "本月",
          rankDataArr: [],
        },
      ],
    },
    {
      id: 3,
      name: "运动时间",
      rankingArr: [
        {
          id: 11,
          name: "今日",
          rankDataArr: [],
        },
        {
          id: 12,
          name: "本周",
          rankDataArr: [],
        },
        {
          id: 13,
          name: "本月",
          rankDataArr: [],
        },
      ],
    },
  ],
  selectRankingIndex: 0,
  inRankingIndex: 0,
});

const remarkCarusel = ref(null);
const rankingInChange = (e) => {
  rankingData.inRankingIndex = e;
  if (Number(e) === 2) {
    setTimeout(() => {
      if (
        Number(rankingData.selectRankingIndex) ===
        rankingData.selectRankingArr.length - 1
      )
        rankingData.selectRankingIndex = 0;
      else ++rankingData.selectRankingIndex;
    }, 4000);
  }
};

const labelNameRanking = index => {
  remarkCarusel.value.swipeTo(index);
  rankingData.selectRankingIndex = index
}

const setImageSrc = e => {
  e.target.src = deficonHead
  
}


watch(
  () => rankingData.selectRankingIndex,
  (newVal) => {
    remarkCarusel.value.swipeTo(newVal);
  }
);

// 运动里程
const initRankingMotion = async () => {
  // 今日
  const todayRanking = await rankingMotion({
    endTime: getDateFn.getDate(),
    startTime: getDateFn.getDate(),
    limit: 20
  });
  if (todayRanking) rankingData.selectRankingArr[0].rankingArr[0].rankDataArr = todayRanking;
  // 本周
  const weekRanking = await rankingMotion({
    endTime: getDateFn.getMonday('e'),
    startTime: getDateFn.getMonday('s'),
    limit: 20
  });
  if (weekRanking) rankingData.selectRankingArr[0].rankingArr[1].rankDataArr = weekRanking;
  // 本月
  const monthRanking = await rankingMotion({
    endTime: getDateFn.getMonth('e',0),
    startTime: getDateFn.getMonth('s',0),
    limit: 20
  });
  if (monthRanking)  rankingData.selectRankingArr[0].rankingArr[2].rankDataArr = monthRanking;


};

// 消耗热量
const initRankingCalorie = async () => {
  // 今日
  const todayRanking = await rankingCalorie({
    endTime: getDateFn.getDate(),
    startTime: getDateFn.getDate(),
    limit: 20
  });
  if (todayRanking)
    rankingData.selectRankingArr[1].rankingArr[0].rankDataArr =
      todayRanking;
  // 本周
  const weekRanking = await rankingCalorie({
    endTime: getDateFn.getMonday('e'),
    startTime: getDateFn.getMonday('s'),
    limit: 20
  });
  if (weekRanking) rankingData.selectRankingArr[1].rankingArr[1].rankDataArr = weekRanking;


  // 本月
  const monthRanking = await rankingCalorie({
    endTime: getDateFn.getMonth('e',0),
    startTime: getDateFn.getMonth('s',0),
    limit: 20
  });
  if (monthRanking) rankingData.selectRankingArr[1].rankingArr[2].rankDataArr = monthRanking;

};

// 运动时间
const initRankingTime = async () => {
  // 今日
  const todayRanking = await rankingTime({
    endTime: getDateFn.getDate(),
    startTime: getDateFn.getDate(),
    limit: 20
  });
  if (todayRanking) rankingData.selectRankingArr[2].rankingArr[0].rankDataArr = todayRanking;
  // 本周
  const weekRanking = await rankingTime({
    endTime: getDateFn.getMonday('e'),
    startTime: getDateFn.getMonday('s'),
    limit: 20
  });
  if (weekRanking) rankingData.selectRankingArr[2].rankingArr[1].rankDataArr = weekRanking;
  // 本月
  const monthRanking = await rankingTime({
    endTime: getDateFn.getMonth('e',0),
    startTime: getDateFn.getMonth('s',0),
    limit: 20
  });
  if (monthRanking) rankingData.selectRankingArr[2].rankingArr[2].rankDataArr = monthRanking;
};

// RFID 排行榜
const initRankingRfid = async () => {
  // 今日
  const todayRanking = await rankingTime({
    endTime: getDateFn.getDate(),
    startTime: getDateFn.getDate(),
    limit: 20,
    flag: 2
  });
  if (todayRanking) rankingData.selectRankingArr[3].rankingArr[0].rankDataArr = todayRanking;
}

const userInfoStoreConfig = userInfoStore()
onMounted(() => {
  initRankingMotion();
  initRankingCalorie();
  initRankingTime();
  // 广西贵港项目 显示rfid排行榜 只有管理员账号或者广西贵港项目账号可以查看
  if (userInfoStoreConfig.userInfo.locations.indexOf('1860149090780737537') !== -1 || userInfoStoreConfig.userInfo.dutyCode == '001') {
    rankingData.selectRankingArr.push({
      id: 4,
      name: "RFID排行",
      rankingArr: [
        {
          id: 11,
          name: "今日",
          rankDataArr: [],
        }
      ],
    })
    initRankingRfid();
  }
});
</script>

<style lang="scss" scoped>
.ranking-page-box {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/ranking-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: .375rem;
  box-sizing: border-box;
  overflow: hidden;
  /* 滚动条整体样式 */
  ::-webkit-scrollbar {
    width: 5px; /* 滚动条宽度 */
  }
  .card-title {
    margin-bottom: .25rem;
  }
}
.ranking-b-box {
  width: 100%;
  height: 100%;
  .my-swipe {
    width: 100%;
    height: 100%;
    .van-swipe__track {
      width: 100%;
    }
  }
  .in-swiper-box {
    width: 100%;
    height: 100%;
  }
}
.user-ranking-box {
  width: 100%;
  .ranking-item-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l-user-detail {
      display: flex;
      align-items: center;
      .rank-num {
              width: .613rem;
              height: .688rem;
              font-size: .3rem;
              color: white;
              font-style: oblique;
              line-height: .688rem;
              text-align: center;
              margin-right: .138rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
      .head-img {
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: .575rem;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        font-size: .225rem;
        color: #8DE0FF;
        width: 1.2rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: .125rem;
        box-sizing: border-box;
      }
    }
    .r-run-detail {
        font-size: .225rem;
        color: #8DE0FF;
        display: flex;
        align-items: center;
        .run-time {
          margin-right: .125rem;
          flex-shrink: 0;
        }
    }

  }
}
.t-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rank-name-select {
    color: white;
    display: flex;
    align-items: center;
    font-size: .16rem;
    line-height: 1;
    .label-name {
      padding: .063rem .1rem;
      cursor: pointer;
    }
    .label-name-active {
      color: #25AFF5;
      font-size: .18rem;
      font-weight: bold;
    }
  }
}
.user-ranking-content {
  overflow-y: auto;
  padding-bottom: 80px;
}
</style>
