<template>
  <div class="device-use-page">
    <div class="card-title">设备使用排行</div>
    <div class="device-use-in-box">
        <div class="item-device-ranking" v-for="(item,index) in deviceRanking" :key="index">
            <div class="label-name one-label-use">
                    {{ item.deviceType }}:
                    <span style="color: #EB6100;">{{ item.percentage.toFixed(2) * 100 }}</span>
                    %
            </div>
            <div class="label-name two-interactive">
                互动:
                <span style="color: #DACD01;">{{ item.totalUseCount }}</span>
                人
            </div>
            <div class="label-name three-useAdd">
                新增:
                <span style="color: #00C900;">{{ item.newAddUseCount }}</span>
                人
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getDeviceRanking } from '../../../api/count'

const deviceRanking = ref([])
onMounted(() => {
    getDeviceRanking().then(res => {
        deviceRanking.value = res
    }) 
})
</script>

<style lang="scss" scoped>
.device-use-page {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/device-use-ranking-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: .375rem .25rem;
    box-sizing: border-box;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0px
    }
    .card-title {
        margin-bottom: .313rem;
    }
    .device-use-in-box {
        display: flex;
        flex-direction: column;
        .item-device-ranking {
            display: flex;
            align-items: center;
            margin-bottom: .313rem;
            .label-name {
                font-size: .225rem;
                font-weight: bold;
                color: #C1D0EE;
                white-space: nowrap;
                span {
                    display: inline-block;
                    margin-left: .075rem;
                }
            }
            .one-label-use {
                width: 50%;
                padding-right: .1rem;
                box-sizing: border-box;
            }
            .two-interactive {
                width: 25%;
            }
            .three-useAdd {
                width: 25%;
                text-align: right;
            }
        }
    }
}
</style>