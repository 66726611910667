<template>
  <div class="device-box">
    <div class="card-title">设备列表</div>
    <div class="device-list-box" ref="deviceListBoxDom">
      <div class="device-list" ref="deviceListDom" :style="{transform: `translateY(${lateY}px)`}">
        <div class="device-label-item" v-for="(item,index) in deviceList" :key="index">
            <div class="device-name">{{ item.deviceName }}</div>
            <div class="label-r-content">
                <div class="online-status">
                  <div class="online-img"><img :src="item.switchStatus ? onlineImg : offImg" alt=""></div>
                  <div class="">{{ item.switchStatus ? '开' : '关'}}</div>
                </div>
                <div class="boot-status">
                  <div class="boot-img"><img :src="item.onlineStatus == 1 ? bootOnlineImg : bootOfflineImg" alt=""></div>
                  <div>{{ item.onlineStatus == 1 ? '在线' : '离线'}}</div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="device-status-chat">
        <div class="device-chat" ref="deviceOnline"></div>
        <div class="device-chat" ref="deviceBoot"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getDeviceCount } from '../../../api/count'
import onlineImg from '../../../assets/images/on-icon.png'
import offImg from '../../../assets/images/off-icon.png'
import bootOnlineImg from '../../../assets/images/online-icon.png'
import bootOfflineImg from '../../../assets/images/offline-icon.png'
// 引入echarts
import * as echarts from "echarts"

const deviceList = ref([])
const deviceOnline = ref(null)
const deviceBoot = ref(null)

const deviceListBoxDom = ref(null)
const deviceListDom = ref(null)
const lateY = ref(0)

onMounted(() => {
    getDeviceCount().then(res => {
        if (res) {
            deviceList.value = res.deviceList
            initEchart([{name: '开机' + res.powerOn, value: res.powerOn}, {name: '关机' + res.powerOff, value: res.powerOff}], deviceOnline, {name: '设备开关', color: ['#0fae31', '#C2C2C2']})
            initEchart([{name: '在线' + res.online, value: res.online}, {name: '离线' + res.offline, value: res.offline}], deviceBoot, {name: '设备状态', color: ['#F7A000', '#C2C2C2']})

            setTimeout(() => {
              if (deviceListDom.value.clientHeight > deviceListBoxDom.value.clientHeight) {
               try{
                setInterval(() => {
                  if(lateY.value < -(deviceListDom.value?.clientHeight - deviceListBoxDom.value?.clientHeight)) lateY.value = 0
                  --lateY.value
                }, 20)
               } catch(err) {}
              }
            }, 1000)
        }
    })


})

 const initEchart = (valueData, domBox, { name, color }) => {
    let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            bottom: '5px',
            left: 'center',
            textStyle: {
              color: '#fff',
              marginLeft: '10px'
            }
          },
          color,
          series: [
            {
              width: '80%',
              left: 'center',
              top: -20,
              name: name,
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                textStyle:{
                  color:'#FFF'
                },
                show: true,
                position: 'outside'

              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                normal:{
                    length:0,   	// 指示线长度
                    lineStyle: {
                        color: "#FFF"  // 指示线颜色
                    }
                },
              },
              data: valueData
            }
          ]
          }

          let canvasDom = echarts.init(domBox.value, null, {renderer: 'svg'})
          canvasDom.setOption(option)
  }
</script>

<style lang="scss" scoped>
.device-box {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/device-use-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: .375rem .25rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
   .card-title {
    margin-bottom: .313rem;
   }
   .device-list-box {
        height: 3.75rem;
        overflow-y: scroll;
        border-bottom: solid 1px #96C2EA;
        scrollbar-width: none !important; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          width: 0;
        }
        .device-label-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: .25rem;
            .device-name {
                width: 50%;
                font-size: .225rem;
                color: #C1D0EE;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: .125rem;
            }
            .label-r-content {
                font-size: .225rem;
                color: #C1D0EE;
                display: flex;
                align-content: center;
                .online-status {
                  display: flex;
                  align-items: center;
                  margin-right: .25rem;
                  .online-img {
                    width: .438rem;
                    margin-right: .125rem;
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                }
                .boot-status {
                  display: flex;
                  align-items: center;
                  .boot-img {
                    width: .3rem;
                    margin-right: .125rem;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
            }
        }
    }
    .device-status-chat {
        display: flex;
        flex: 1;
        .device-chat {
            flex: 1;
        }
    }
}

@media (max-width: 750px) {
  .device-status-chat {
        display: flex;
        flex: 1;
        min-height: 4rem !important;
        .device-chat {
            flex: 1;
        }
    }
}

</style>
