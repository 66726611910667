<template>
  <div class="head">
      <!-- 左侧时间栏 -->
      <div class="time-box mobile-none">
        <div><span>{{ timeData.date }}</span> <span>{{ timeData.time }}</span></div>
        <div>{{ timeData.weekName }}</div>
      </div>
      <!-- 中间标题 -->
      <div class="head-title" @click="addAdminChange">
          <img class="head-logo" :src="$formattingImg(configData.trailData.logo)" alt="">
          <span>{{ configData.trailData.title }}</span>
      </div>
      <!-- 右侧天气 -->
      <div class="weather-box flex flex-column mobile-none">
          <div class="weather-top  flex align-center">
                <div class="flex align-center">
                    <div class="weather-icon"><i :class="`qi-${weaTherStore.weaTher.icon}`"></i></div>
                    <div class="flex"><span>{{ weaTherStore.weaTher.temp }}°C</span>  <span>{{ weaTherStore.weaTher.text }}°</span></div>
                </div>
          </div>
          <div class="bottom-weather flex align-center">
              <div class="label-weather flex align-center">
                  <div class="label-weather-icon"><img src="../../assets/images/wet.png" alt=""></div>
                  <div>湿度{{ weaTherStore.weaTher.humidity }}%</div>
              </div>
              <div class="label-weather flex align-center">
                  <div class="label-weather-icon"><img src="../../assets/images/feng.png" alt=""></div>
                  <div>{{ weaTherStore.weaTher.windDir }}</div>
              </div>
              <div class="label-weather flex align-center">
                  <div class="label-weather-icon"><img src="../../assets/images/air.png" alt=""></div>
                  <div>{{ weaTherStore.weaTher.category }}</div>
              </div>
          </div>
      </div>

      <van-overlay :show="adminShow" :z-index="9999">
        <div class="wrapper" @click.stop>
            <div class="alert-show-box flex align-center flex-column">
                <div class="alart-title">提示</div>
                <div class="input-main">
                    <input type="password" v-model="pwd" placeholder="请输入密码">
                </div>
                <div class="btn-box flex">
                    <div class="flex justify-center flex-1" @click="adminShow = false">取消</div>
                    <div class="flex justify-center flex-1" @click="submitConsole">确定</div>
                </div>
            </div>
         </div>
      </van-overlay>
  </div>
  <div style="height: 1.337rem;"></div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { weaTherUseStore } from "../../store/weather";
import { configStore } from "../../store/config";
import { utilTimeShow } from "../../utils/util";
import { useRouter } from "vue-router";
import { Overlay, Toast } from "vant"

// 初始化
const weaTherStore = weaTherUseStore();
const configData = configStore();
const emit = defineEmits(['backChange'])
const router = useRouter()
const props = defineProps({
    // 是否需要返回
    isBack: {
        type: Boolean,
        default: false
    },
    // 返回倒计时
    timeOut: {
        type: Number,
        default: 30
    },
    isClickAddTime: {
        type: Boolean,
        default: false
    },
    // 用户信息
    userInfo: {
        type: Object,
        default: null
    }
})

onMounted(() => {
    // 初始化时间
    getTime()
})

/**
 * _________________________________________________________________________________________________________
 * 时间倒计时
 */
let timeDown = ref(0)
let setClearTime
const timeDownChange = () => {
    if(timeDown.value == 0) {
        clearTimeout(setClearTime)
        emit('backChange', true)
    }
    --timeDown.value
    setClearTime = setTimeout(() => timeDownChange(), 1000)
}
watch(() => props.isBack, () => {
    if(props.isBack) {
        timeDown.value = props.timeOut;
        timeDownChange()
    } else clearTimeout(setClearTime)
}, { immediate: true })

watch(() => props.isClickAddTime, (newVal) => {
    if(newVal) document.addEventListener('click', () => timeDown.value = props.timeOut)
}, { immediate: true })
onUnmounted(() => {
    clearTimeout(setClearTime)
})
//___________________________________________________________________________________________________________



/**
 * ___________________________________________________________________________________________________________
 * 日期时间倒计时
 */
let timeData = reactive({})
const getTime = () => {
    let { date, time, weekName } = utilTimeShow()
    timeData.date = date
    timeData.time = time
    timeData.weekName = weekName
    setTimeout(() => getTime(), configData.config.time * 1000)
}
//____________________________________________________________________________________________________________

// 控制台应用
let adminNum = ref(0)
let adminShow = ref(false)
let pwd = ref()
const addAdminChange = () => {
    ++adminNum.value;
    if(adminNum.value % 10 == 0) adminShow.value = true
}
const submitConsole = () => {
    if(pwd.value == 'huirenzhineng1688')  router.push('/console')
    else {
        pwd.value = ''
        Toast({
            message: '密码错误，请重新输入',
            position: 'bottom'
        })
    }
}
</script>

<style lang="scss">
.test {
    color: white;
}
.head {
    width: 100%;
    height: 1.337rem;
    // background-image: url('../../assets/images/head.9.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    .head-title {
        width: 11.963rem;
        background-image: url('../../assets/images/header-bg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        font-size: .55rem;
        color: #A1EDEA;
        letter-spacing: 3px;
        text-align: center;
        line-height: 1.137rem;
        display: flex;
        flex: 1;
        justify-content: center;
        .head-logo {
            max-height: 1rem;
            margin: auto .188rem;
        }
    }
    .time-box {
        position: absolute;
        color: white;
        top: .25rem;
        left: 4%;
        font-size: .3rem;
        line-height: 1.4;
    }
    .head-detail-box {
        position: absolute;
        top: .525rem;
        left: 4%;
        line-height: 1;
        .head-img {
            width: .8rem;
            height: .8rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: .188rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .top-user-detail {
            font-size: .3rem;
            color: white;
            font-weight: bold;
            margin-bottom: .15rem;
        }
        .bottom-user-detail {
            font-size: 16px;
            color: #8FA3CC;
            font-weight: bold;
        }
    }
    .weather-box {
        position: absolute;
        right: 5%;
        top: .25rem;
        align-items: flex-end;
        .weather-top {
            font-size: .3rem;
            line-height: 1;
            color: white;
            font-weight: 600;
            margin-bottom: .15rem;
            .weather-icon {
                width: .45rem;
                margin-right: .18rem;
                font-size: .4rem;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            span {
                margin-right: .225rem;
                display: inline-block;
            }
        }
        .quit-time {
            color: white;
            line-height: 1;
            font-size: .273rem;
            margin-left: .125rem;
        }
        .bottom-weather {
            .label-weather {
                font-size: .2rem;
                color: #8FA3CC;
                margin-right: .258rem;
                display: flex;
                align-items: center;
                line-height: 1;
                font-weight: bold;
                .label-weather-icon {
                   height: .212rem;
                   margin-right: .093rem;
                   img {
                       width: auto;
                       height: 100%;
                   }
                }
            }
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .alert-show-box {
            width: 30vw;
            background: #043ec6;
            border-radius: .25rem;
            padding-top: .375rem;
            box-sizing: border-box;
            .alart-title {
                font-size: .325rem;
                color: white;
            }
            .input-main {
                width: 100%;
                height: .675rem;
                padding: 0 .375rem;
                box-sizing: border-box;
                margin: .475rem 0;
                input {
                    width: 100%;
                    height: 100%;
                    background-color: #0e12cd;
                    outline: none;
                    color: white;
                    border: none;
                    padding: .1rem .3rem;
                    border-radius: .375rem;
                    font-size: .3rem;
                    box-sizing: border-box;
                }
            }
            .btn-box {
                width: 100%;
                border-top: solid #0010bd .025rem;
                & > div {
                   font-size: .3rem;
                   color: white;
                   font-weight: bold;
                   padding: .25rem 0;
                   cursor: pointer;
                }
                & >div:nth-of-type(1) {
                    border-right: solid #0010bd .025rem;
                }
            }
        }
    }

}

@media (max-width: 750px) {
  .head .head-title {
    width: 100% !important;
  }
}
</style>
